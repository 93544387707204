import { Box, Button, Container, Typography } from "@mui/material";
import { ReactComponent as Hands } from "images/hands.svg";

export const TitleBlock = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          pt: 16,
          pb: 16,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#023169",
              fontFamily: "Actay Wide",
              fontSize: "110px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            RewritePro
          </Typography>
          <Typography
            sx={{
              color: "#022754",
              fontFamily: "Evolventa",
              fontSize: "22px",
              width: "590px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
          >
            Теперь сделать текст уникальным просто!
          </Typography>
        </Box>
        <Box>
          <a href="#authButton">
            <Button
              sx={{
                color: "white",
                background: "#023169",
                textTransform: "none",
                pl: "20px",
                pr: "20px",
                height: "40px",
              }}
            >
              Попробовать
            </Button>
          </a>
        </Box>
      </Box>
      <Hands />
    </Container>
  );
};
