import {
  Box,
  Button,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  Rating,
  Typography,
} from "@mui/material";
import { useState } from "react";

type Props = {
  value: MyReview | null;
  setValue: (value: MyReview | null) => void;
};

enum ReviewReason {
  none = "none",
  misrepresent = "misrepresent",
  itIsBot = "it is bot",
  wrongStyle = "wrong style",
  other = "other",
}

export class MyReview {
  constructor(
    public readonly value: number,
    public readonly reason: ReviewReason,
    public readonly reasonText?: string,
    private readonly sent?: boolean,
  ) {}

  isBad(): boolean {
    return this.value < 4;
  }

  hasRating(): boolean {
    return this.value > 0;
  }

  hasReason(): boolean {
    return this.reason && this.reason != ReviewReason.none;
  }

  isSent() {
    return this.sent || !this.isBad();
  }
}

const styles = {
  container: {
    pt: "42px",
    pb: "53px",
    textAlign: "center",
  },
  title1: {
    fontSize: "24px",
  },
  title2: {
    fontSize: "24px",
    mt: "24px",
  },
  rating: {
    fontSize: "54px",
    mt: "12px",
    mb: "12px",
  },
  help_us: {
    margin: "auto",
    mt: "12px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  input: {
    width: "350px",
    ml: "130px",
    mb: "12px",
  },
  button: {
    padding: "8px 36px",
    borderRadius: "0px",
    backgroundColor: "secondary.main",
    fontSize: "14px",
    color: "primary.light",
    textTransform: "none",
  },
};

const Review: React.FC<Props> = ({ value, setValue }) => {
  const [reason, setReason] = useState<ReviewReason | null>(
    value?.reason || null,
  );
  const [reasonText, setReasonText] = useState<string>(value?.reasonText || "");
  const [rating, setRating] = useState<number | null>(value?.value || null);
  const onSend = () => {
    setValue(
      new MyReview(
        rating || 0,
        reason || ReviewReason.none,
        reasonText || "",
        true,
      ),
    );
  };

  const onChangeRating = (val: number | null) => {
    setRating(val);
    setValue(
      new MyReview(
        val || 0,
        reason || ReviewReason.none,
        reasonText || "",
        false,
      ),
    );
  };

  if (value?.isSent()) {
    return (
      <Box sx={styles.container}>
        <Typography variant="h3" sx={styles.title1}>
          Благодарим за оценку!
        </Typography>
      </Box>
    );
  }
  return (
    <Box sx={styles.container}>
      <Typography variant="h3" sx={styles.title1}>
        Оцените полученный результат
      </Typography>
      <Rating
        sx={styles.rating}
        size="large"
        value={value?.value}
        readOnly={value?.hasRating()}
        onChange={(_event, newValue) => {
          onChangeRating(newValue);
        }}
      />
      {value?.isBad() && (
        <>
          <Typography variant="h3" sx={styles.title2}>
            Что не так? Помогите нам стать лучше
          </Typography>
          <Box sx={styles.help_us}>
            <RadioGroup
              defaultValue=""
              value={reason}
              onChange={(_, r) => setReason(r as ReviewReason)}
              name="rating-description"
            >
              <FormControlLabel
                value={ReviewReason.misrepresent}
                control={<Radio />}
                label="Искажен смысл текста"
              />
              <FormControlLabel
                value={ReviewReason.itIsBot}
                control={<Radio />}
                label="Видно, что робот писал"
              />
              <FormControlLabel
                value={ReviewReason.wrongStyle}
                control={<Radio />}
                label="Выбран неудачный стиль"
              />
              <FormControlLabel
                value={ReviewReason.other}
                control={<Radio />}
                label="Другое"
              />
            </RadioGroup>
          </Box>
          {reason === "other" && (
            <Box sx={styles.help_us}>
              <Input
                sx={styles.input}
                disableUnderline={true}
                value={reasonText}
                onChange={(event) => {
                  setReasonText(event.target.value);
                }}
                slotProps={{
                  input: {
                    placeholder: "Что именно?",
                    type: "text",
                  },
                }}
              />
            </Box>
          )}
          <Box>
            <Button
              sx={styles.button}
              onClick={onSend}
              disabled={!reason || reason == ReviewReason.none}
            >
              Отправить
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Review;
