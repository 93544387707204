import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as FirstAboutImage } from "images/firstAboutImage.svg";
import { ReactComponent as SecondAboutImage } from "images/secondAboutImage.svg";
import { ReactComponent as ThirdAboutImage } from "images/thirdAboutImage.svg";
import { ReactComponent as FourthAboutImage } from "images/fourthAboutImage.svg";

const informationRows = [
  {
    title: "Уникальность на первом месте",
    subTitle:
      "Уникальный алгоритм приложения позволяет перерабатывать текст с высокой степенью уникальности, при этом текст не определяется, как сгенерированный.",
    image: FirstAboutImage,
  },
  {
    title: "Сохранение смысла",
    subTitle:
      "Наша технология обеспечивает сохранение первоначального смысла и информации текста, даже после рерайта. Смысл будет сохранён даже в сложных текстах, технических или юридических",
    image: SecondAboutImage,
  },
  {
    title: "Простота использования",
    subTitle:
      "Нет необходимости в сложных настройках. Просто вставьте свой исходный текст, нажмите на кнопку переработки и получите новую версию текста.",
    image: ThirdAboutImage,
  },
  {
    title: "Конфиденциальность",
    subTitle:
      "Мы ценим вашу конфиденциальность. Ваши тексты и другие данные не сохраняются и не используются для других целей, кроме рерайта.",
    image: FourthAboutImage,
  },
];

export const AboutBlock = () => {
  return (
    <Grid container direction="row" rowSpacing={4}>
      <Grid item xs={12}>
        <Typography
          sx={{
            color: "#023169",
            fontFamily: "Actay Wide",
            fontSize: "30px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
          }}
        >
          О сервисе
        </Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography
          sx={{
            color: "#726D6D",
            fontFamily: "Evolventa",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
          }}
        >
          Наше приложение спроектировано для того, чтобы помочь вам сэкономить
          время и усилия, значительно увеличивая уникальность текстов, но
          сохраняя при этом изначально заложенный смысл.
        </Typography>
      </Grid>
      <Grid item xs={12} justifyContent={"flex-end"}>
        <Typography
          sx={{
            color: "#023169",
            textAlign: "right",
            fontFamily: "Actay Wide",
            fontSize: "30px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            width: 500,
            ml: "auto",
            pb: 10,
          }}
        >
          Что делает наше приложение особенным?
        </Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent={"center"}
        alignItems={"center"}
        marginLeft={8}
        marginRight={8}
      >
        {informationRows.map((row, index) => {
          if (index % 2 == 0) {
            return (
              <>
                <Grid item xs={12} md={6}>
                  <Box width={400} flexDirection={"column"}>
                    <Typography
                      sx={{
                        color: "#023169",
                        fontFamily: "Evolventa",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                        pb: 2,
                      }}
                    >
                      {row.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#726D6D",
                        fontFamily: "Evolventa",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                      }}
                    >
                      {row.subTitle}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={0} md={6}>
                  {<row.image />}
                </Grid>
              </>
            );
          } else {
            return (
              <>
                <Grid item xs={0} md={6}>
                  {<row.image />}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box width={400} flexDirection={"column"}>
                    <Typography
                      sx={{
                        color: "#023169",
                        fontFamily: "Evolventa",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                        pb: 2,
                      }}
                    >
                      {row.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#726D6D",
                        fontFamily: "Evolventa",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                      }}
                    >
                      {row.subTitle}
                    </Typography>
                  </Box>
                </Grid>
              </>
            );
          }
        })}
      </Grid>
    </Grid>
  );
};
