import { Box, Button, Typography, Avatar } from "@mui/material";
import { observer } from "mobx-react";
import { ReactElement } from "react";
import { useUserStore } from "stores/useUserStore";

export const RewriterAppBar = observer((): ReactElement => {
  const userStore = useUserStore();

  const logout = () => {
    userStore.logout();
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        background: "#c0d3e9",
        borderRadius: "0px 0px 40px 40px",
        padding: "22px 120px 22px 165px",
      }}
    >
      <Typography
        sx={{
          font: "700 18px Evolventa",
          color: "#023169",
          pt: 1,
        }}
      >
        RewritePro
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDireaction: "row",
          alignItems: "center",
        }}
      >
        {userStore.isAccount() && (
          <Avatar
            alt={userStore.userName()}
            src={userStore.userAvatar()}
            sx={{ width: 56, height: 56, marginRight: 2 }}
          >
            {!userStore.hasUserAvatar() && userStore.stringAvatar()}
          </Avatar>
        )}
        {(userStore.isAccount() && (
          <Button
            onClick={logout}
            sx={{
              display: "flex",
              height: "40px",
              color: "#023169",
              background: "white",
              border: "none",
              textTransform: "none",
              pl: "30px",
              pr: "30px",
            }}
          >
            Выйти
          </Button>
        )) || (
          <a href="#authButton">
            <Button
              sx={{
                display: "flex",
                height: "40px",
                color: "#023169",
                background: "white",
                border: "none",
                textTransform: "none",
                pl: "30px",
                pr: "30px",
              }}
            >
              Войти
            </Button>
          </a>
        )}
      </Box>
    </Box>
  );
});
