import { Container, Grid, Typography } from "@mui/material";
import LoginOrRegister from "components/common/LoginOrRegister";

export const TryServiceBlock = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        mb: 10,
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={{
              alignContent: "center",
              textAlign: "center",
              color: "#023169",
              fontFamily: "Actay Wide",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
              width: 800,
              ml: "auto",
              mr: "auto",
              mt: 4,
              pt: 10,
              pb: 10,
            }}
          >
            Попробуйте сервис бесплатно <br /> прямо сейчас
          </Typography>
        </Grid>
        <LoginOrRegister />
      </Grid>
    </Container>
  );
};
