import { Box, Button, Typography } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";

import { observer } from "mobx-react";
import { useUserStore } from "stores/useUserStore";
import { useRewriteStore } from "stores/useRewriteStore";
import { RequestState } from "types/RequestState";
import Review, { MyReview } from "./Review";
import OutputArea from "./OutputArea";
import TextArea from "./TextArea";

const styles = {
  container: {
    pt: "42px",
    pb: "53px",
  },
  subtitle: {
    mb: "8px",
    ml: "32px",
  },
  textAreas: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  bottom: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    padding: "8px 36px",
    borderRadius: "40px",
    backgroundColor: "secondary.main",
    fontSize: "14px",
    color: "primary.light",
    textTransform: "none",
  },
};

const Rewriter = observer((): ReactElement => {
  const userStore = useUserStore();
  const rewriteStore = useRewriteStore();
  const [text, setText] = useState("");
  const [outText, setOutText] = useState("");
  const [errorText, setErrorText] = useState<string | null>(null);
  const [review, setReview] = useState<MyReview | null>(null);
  const [isReviewClosed, setReviewClosed] = useState<boolean>(false);
  const requestCount = userStore.amountAvailableRequests();

  useEffect(() => {
    if (review?.isSent()) {
      rewriteStore
        .review(review.value, review.reason, review.reasonText)
        .catch((error) => {
          console.log(error);
        });
      const timeout = setTimeout(() => setReviewClosed(true), 3000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [review]);

  const onRequest = () => {
    rewriteStore
      .request(text)
      .then((txt) => {
        setErrorText(null);
        setOutText(txt);
      })
      .catch(() => {
        setErrorText("Ошибка обработки запроса, попробуйте позже");
      })
      .finally(() => {
        setReview(null);
        setReviewClosed(false);
      });
  };

  const onChangeRating = (value: MyReview | null) => {
    console.log("rating", value);
    setReview(value);
  };

  const inProgress = rewriteStore.state == RequestState.LOADING;
  const disabled = text.length < 15 || requestCount < 1 || inProgress;
  const waitReview = !inProgress && !isReviewClosed && (outText || errorText);

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.subtitle} variant="subtitle1">
        Осталось запросов: {requestCount}
      </Typography>
      <Box sx={styles.textAreas}>
        <TextArea text={text} setText={setText} />
        <OutputArea output={outText} error={errorText} loading={inProgress} />
      </Box>
      {waitReview && <Review value={review} setValue={onChangeRating} />}
      <Box sx={styles.bottom}>
        <Button sx={styles.button} disabled={disabled} onClick={onRequest}>
          Рерайт
        </Button>
      </Box>
    </Box>
  );
});

export default Rewriter;
