import { Typography } from "@mui/material";
import React from "react";

type Props = {
  children: string;
};

const Title: React.FC<Props> = ({ children }) => {
  return (
    <Typography
      sx={{
        ml: "75px",
        pt: "17%",
        maxWidth: "625px",
        fontFamily: "Actay Wide",
        fontSize: "80px",
        fontWeight: 700,
        color: "#023169",
      }}
    >
      {children}
    </Typography>
  );
};

export default Title;
