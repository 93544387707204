import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Box, Grid } from "@mui/material";
import { ReactComponent as TryServiceImage } from "images/tryServiceImage.svg";
// import DividerWithText from "./DividerWithText";
import { Config, Connect, ConnectEvents } from "@vkontakte/superappkit";
import { VkPayload } from "types/VkPayload";
import { IUserStore } from "stores/UserStore";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "stores/useUserStore";

type Props = {
  titleFz?: number;
};

const styles = {
  title: {
    color: "secondary.main",
    fontFamily: "Actay Wide",
    width: 300,
  },
  content: {
    maxWidth: "533px",
  },
  button: {
    mt: 5,
    pl: "20px",
    pr: "20px",
    width: "100%",
    height: "55px",
    color: "primary.light",
    backgroundColor: "secondary.main",
    fontSize: "18px",
    textTransform: "none",
  },
  buttonYandex: [
    {
      "&:hover": {
        borderColor: "#FC3F1D",
      },
      display: "flex",
      columnGap: "14px",
      pl: "20px",
      pr: "20px",
      width: "100%",
      height: "55px",
      color: "rgba(114, 109, 109, 0.40)",
      backgroundColor: "transparent",
      border: "2px solid #023169",
      textTransform: "none",
      fontSize: "18px",
    },
  ],
};

console.log(process.env.REACT_APP_HOST);

const LoginOrRegister: React.FC<Props> = observer(() => {
  const [vkButtonRendered, setVkButtonRendered] = useState(false);
  const userStore: IUserStore = useUserStore();
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleLogin = (payload: VkPayload) => {
      userStore
        .loginVk(payload)
        .then(() => {
          if (userStore.isAccount()) {
            navigate("/account");
          }
        })
        .catch(() => setIsError(true));
    };
    if (!vkButtonRendered) {
      Config.init({
        appId: Number(process.env.REACT_APP_VK_ID), // идентификатор приложения
      });
      const oneTapButton = Connect.buttonOneTapAuth({
        // Обязательный параметр в который нужно добавить обработчик событий приходящих из SDK
        callback: function (e) {
          console.log("!!!!", e);
          const type = e.type;

          if (!type) {
            return false;
          }

          switch (type) {
            case ConnectEvents.OneTapAuthEventsSDK.LOGIN_SUCCESS: // = 'VKSDKOneTapAuthLoginSuccess'
              console.log(e);
              if (e && e.payload) {
                handleLogin(e.payload as unknown as VkPayload);
              }
              return false;
            // Для этих событий нужно открыть полноценный VK ID чтобы
            // пользователь дорегистрировался или подтвердил телефон
            case ConnectEvents.OneTapAuthEventsSDK.FULL_AUTH_NEEDED: //  = 'VKSDKOneTapAuthFullAuthNeeded'
            case ConnectEvents.OneTapAuthEventsSDK.PHONE_VALIDATION_NEEDED: // = 'VKSDKOneTapAuthPhoneValidationNeeded'
            case ConnectEvents.ButtonOneTapAuthEventsSDK.SHOW_LOGIN: // = 'VKSDKButtonOneTapAuthShowLogin'
              return Connect.redirectAuth({
                url: process.env.REACT_APP_HOST + "account",
                state: "dj29fnsadjsd82",
              }); // url - строка с url, на который будет произведён редирект после авторизации.
            // state - состояние вашего приложение или любая произвольная строка, которая будет добавлена к url после авторизации.
            // Пользователь перешел по кнопке "Войти другим способом"
            case ConnectEvents.ButtonOneTapAuthEventsSDK.SHOW_LOGIN_OPTIONS: // = 'VKSDKButtonOneTapAuthShowLoginOptions'
              // Параметр url: ссылка для перехода после авторизации. Должен иметь https схему. Обязательный параметр.
              return Connect.redirectAuth({
                url: process.env.REACT_APP_HOST + "account",
                state: "sdfjlsdfjlksjdf",
              });
          }

          return false;
        },
        // Не обязательный параметр с настройками отображения OneTap
        options: {
          showAlternativeLogin: false, // Отображение кнопки "Войти другим способом"
          displayMode: "name_phone", // Режим отображения кнопки 'default' | 'name_phone' | 'phone_name'
          buttonStyles: {
            borderRadius: 20, // Радиус скругления кнопок
          },
        },
      });
      if (oneTapButton) {
        // Фрэйм с кнопкой можно передать в любой элемент
        document
          ?.getElementById("vkAuthButton")
          ?.appendChild(oneTapButton.getFrame() as Node);
        setVkButtonRendered(true);
      }
    }
  }, []);

  return (
    <>
      <Grid
        item
        display={"flex"}
        direction={"column"}
        justifyContent={"center"}
        xs={12}
        md={7}
      >
        <Box sx={styles.content} id="authButton">
          <div id="vkAuthButton" />
          {isError && <p>Could not login by Vk id</p>}
        </Box>
      </Grid>
      <Grid item xs={0} md={5}>
        <Box>
          <TryServiceImage />
        </Box>
      </Grid>
    </>
  );
});

export default LoginOrRegister;
