import { createTheme } from "@mui/material";

export default createTheme({
  palette: {
    primary: {
      main: "#726D6D",
      light: "#FFFFFF",
    },
    secondary: {
      main: "#023169",
      dark: "#022754",
    },
    background: {
      paper: "#C0D3E9",
    },
    error: {
      main: "#FF0606",
    },
  },
  typography: {
    fontFamily: "Evolventa",
    fontSize: 16,
    fontWeightBold: 700,

    subtitle1: {
      fontSize: 18,
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 400,
    },
    button: {
      fontSize: 12,
      fontWeight: 700,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "40px",
          border: "1px",
          "&:hover": {
            backgroundColor: "#dcdcdc",
            color: "#023169",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          height: "57px",
          paddingLeft: "21px",
          font: "700 16px Evolventa",
          color: "#726d6d",
          border: "2px solid #023169",
          borderRadius: "40px",

          "&.Mui-error": {
            borderColor: "#FF0606 !important",
          },
          "&+.MuiFormHelperText-root": {
            fontSize: "16px",
            color: "red",
          },
        },
        input: {
          width: "100%",
          font: "700 16px Evolventa",
          background: "none",
          border: "none",
          outline: "none",
        },
      },
    },
  },
});
